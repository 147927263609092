export const isBrowser = () => typeof window !== 'undefined'

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('LeafbookUser')
    ? JSON.parse(window.localStorage.getItem('LeafbookUser'))
    : {}

export const setUser = user =>
  window.localStorage.setItem('LeafbookUser', JSON.stringify(user))

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.email
}

export const logout = callback => {
  setUser({})
  callback()
}
