import PropTypes from 'prop-types'
import React from 'react'

import { Link, navigate } from 'gatsby'

import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import PlantsIcon from '@material-ui/icons/LocalFlorist'
import LoginIcon from '@material-ui/icons/LockOpen'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import QRIcon from '@material-ui/icons/CropFree'
import { isLoggedIn, logout } from '../services/auth'

import { StaticQuery, graphql } from 'gatsby'
import { TreeViewer } from 'jetset'

import './layout.css'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'linear-gradient(to right,  #d3959b, #bfe6ba)',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#d3959b',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerPaper: {
    background: '#d3959b',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  main: {
    marginBottom: 40,
    minHeight: 'calc(100vh - 195px)',
  },
  footer: {
    width: '100%',
    padding: 20,
    textAlign: 'center',
  },
}))

const Layout = ({ children, fullWidth }) => {
  const classes = useStyles()
  const theme = useTheme()

  const [open, setOpen] = React.useState(false)

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div
          className={classes.root}
          style={{ minHeight: '100vh', backgroundColor: '#FFF' }}
        >
          <div>
            <CssBaseline />
            <AppBar
              position="fixed"
              elevation={0}
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  color="inherit"
                  component={Link}
                  to="/"
                >
                  {data.site.siteMetadata.title}
                </Typography>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              anchor="left"
              open={open}
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx(classes.drawerPaper, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'ltr' ? (
                    <ChevronLeftIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </IconButton>
              </div>
              <Divider />
              <List>
                <Link to="/">
                  <ListItem button>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText>Home</ListItemText>
                  </ListItem>
                </Link>
                <Link to="/plants">
                  <ListItem button>
                    <ListItemIcon>
                      <PlantsIcon />
                    </ListItemIcon>
                    <ListItemText>All Plants</ListItemText>
                  </ListItem>
                </Link>

                <Link to="/plant-scanner">
                  <ListItem button>
                    <ListItemIcon>
                      <QRIcon />
                    </ListItemIcon>
                    <ListItemText>PlantScanner</ListItemText>
                  </ListItem>
                </Link>
                {isLoggedIn() ? (
                  <>
                    <Divider />
                    <Link to="/admin">
                      <ListItem button>
                        <ListItemIcon>
                          <LoginIcon />
                        </ListItemIcon>
                        <ListItemText>Admin</ListItemText>
                      </ListItem>
                    </Link>
                    <a
                      href="/"
                      onClick={event => {
                        event.preventDefault()
                        logout(() => navigate(`/login`))
                      }}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                      </ListItem>
                    </a>
                  </>
                ) : (
                  <Link to="/login">
                    <ListItem button>
                      <ListItemIcon>
                        <LoginIcon />
                      </ListItemIcon>
                      <ListItemText>Login</ListItemText>
                    </ListItem>
                  </Link>
                )}
              </List>
            </Drawer>
          </div>
          <div
            className={classes.content}
            style={{
              margin: `0 auto`,
              maxWidth: fullWidth ? '100%' : 960,
              padding: `80px 1.0875rem 1.45rem`,
            }}
          >
            <main className={classes.main}>{children}</main>
            <footer className={classes.footer}>
              © {new Date().getFullYear()}, anil@anilmaharjan.com.np
            </footer>
            <TreeViewer />
          </div>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
}

export default Layout
